<template>
  <div v-if="Object.values(isDataReady).every(v => v === true)" class="h-full w-full overflow-y-scroll">
    <div v-if="!alertConfig.research_publisher_id" class="flex h-full w-full flex-col">
      <div class="m-auto">
        <div class="flex h-5 w-full items-center space-x-2">
          <ExclamationCircleIcon class="h-4 w-4 text-gray-400" />
          <span class="block text-xs">Select a Publisher to Visualize</span>
        </div>
      </div>
    </div>
    <span v-else>
      <div class="flex h-full flex-col space-y-4">
        <div class="h-1/4 rounded border border-gray-800 bg-gray-800/25">
          <BaseHistogramChart v-if="showChart" :options="chartOptions" :series="formattedSeries" :bin-number="20" />

          <div class="flex h-full w-full items-center justify-center space-x-2" v-else>
            <ExclamationCircleIcon class="h-4 w-4 text-gray-400" />
            <span class="block text-xs"> No Data Found </span>
          </div>
        </div>
        <div class="flex justify-end">
          <BaseRadio v-model="selectedTimeframe" :options="timeframes.map(t => t.id)" />
        </div>
        <div class="flex-1 overflow-y-scroll rounded border border-gray-800">
          <ResearchShortList class="h-full" :research-articles="articles" />
        </div>
      </div>
    </span>
  </div>
  <div v-else class="flex flex-col pr-8">
    <BaseLoader class="m-auto h-5 w-5" />
  </div>
</template>
<script setup>
import { ref, onMounted, inject, computed } from 'vue';
import { DateTime } from 'luxon';
import { timeframes } from '@/components/alerts/custom/visualization/AlertsCustomVisualizationTimeframes.js';
import BaseHistogramChart from '@/components/chart/BaseHistogramChart.vue';
import ResearchShortList from '@/components/research/ResearchShortList.vue';
import useEmitter from '@/composeables/emitter';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';

const emitter = useEmitter();
const $http = inject('http');
const isDataReady = ref({ articles: true });

const articles = ref([]);
const selectedTimeframe = ref('6M');

const formattedSeries = computed(() => {
  const date = new Date();
  const timeframe = timeframes.find(e => e.id == selectedTimeframe.value);
  const startDatetime = new Date(date.getTime() - timeframe.time).toISOString();
  return [
    {
      name: 'Research Articles',
      data: articles.value
        .filter(e => e.created_at >= startDatetime)
        .map(e => {
          return DateTime.fromISO(e.created_at, { zone: 'utc' }).ts;
        })
    }
  ];
});

const showChart = computed(() => {
  return formattedSeries.value[0].data.length > 0;
});

async function refreshPreview() {
  isDataReady.value.articles = false;
  const params = {
    company: alertConfig.value.research_publisher_id
  };
  let response = await $http.get('/research.json', { params: params });
  articles.value = response.data.articles;
  isDataReady.value.articles = true;
}

const alertConfig = ref({});

onMounted(() => {
  if (props.alert) {
    alertConfig.value = props.alert.config;
    refreshPreview();
  }
  emitter.$on('config:alert', data => {
    alertConfig.value = data.config;
    refreshPreview();
  });
});

const chartOptions = computed(() => {
  const timeframe = timeframes.find(e => e.id == selectedTimeframe.value);
  const date = new Date();
  return {
    chart: {
      id: 'alerts-create-visualization-chart'
    },
    xAxis: {
      type: 'datetime',
      max: DateTime.fromISO(date).ts,
      min: DateTime.fromISO(new Date(date.getTime() - timeframe.time).toISOString()).ts
    },
    tooltip: {
      selectedTimeframe: timeframe
    }
  };
});

const props = defineProps({
  alert: {
    type: Object,
    default: () => null
  }
});
</script>
